<template>
    <div class="profile-card center text-align--left transition--all transition--cubic-bezier">
        <div class="profile-card__body">
            <div class="profile-card__avatar">
                <img :src="avatar" :alt="name" />
            </div>
            <div class="profile-card__text">
                <h3 class="profile-card__title">{{name}}</h3>
                <span class="profile-card__job-title">{{job_title}}</span>
                <span class="profile-card__job-count">{{job_count}} job proposals</span>
            </div>
        </div>
        <div v-if="tags.length" class="profile-card__footer">
            <span v-for="tag in tags" :key="tag" class="profile-card__tag">{{tag}}</span>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    avatar: String,
    name: String,
    job_title: String,
    job_count: String,
    tags: {
        type: Array,
        required: false
    },
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style lang="scss">
.profile-card {
    user-select: none;
    max-width: 500px;
    background-color: #ffffff;
    border-radius: var(--border-radius-default);
    box-shadow: var(--box-shadow-default);
    transition: all .3s cubic-bezier(.2, .5, .3, 1);
    position: relative;

    &:hover {
        transform: translateY(-5px);
        
    }

    &__body {
        padding: var(--padding-smaller);
        padding-left: var(--padding-smallest);
        padding-bottom: var(--padding-smallest);
        display: flex;
        flex-direction: row;
    }
    &__footer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        // grid-gap: .5rem 0.75rem; doesn't work on iOS < 14.7
        padding: var(--padding-smaller);
        padding-bottom: calc(var(--padding-smaller) - .375rem);
        background-color: var(--accent-color-1);
        border-bottom-left-radius: var(--border-radius-default);
        border-bottom-right-radius: var(--border-radius-default);
    }
    &__avatar {
        text-align: center;
        min-width: 100px;
        flex: 0 0 20%;
        margin: 0 30px 0 15px;

        img {
            display: unset;
            border-radius: 100%;
            border: 4px solid #D8CDCD;
            max-height: 100%;
        }
    }
    &__text {
        flex: 1 1 65%;
    }
    &__title {
        font-size: 1.5rem;
    }
    &__job-title {
        display: block;
        margin-bottom: .75rem;
    }
    &__job-count {
        display: inline-block;
        background-color: var(--brand-color-1);
        color: #ffffff;
        padding: .5rem .8rem;
        font-weight: 600;
        font-size: .7rem;
        border-radius: 4px;
        padding-left: 2rem;
        background-image: url('~@/assets/graphics/star.svg');
        background-repeat: no-repeat;
        background-position: 9px center;
    }
    &__tag {
        color: #ffffff;
        padding: .5rem .8rem;
        font-weight: 600;
        font-size: .7rem;
        border-radius: 4px;
        border: 2px solid #ffffff;
        margin: 0 .375rem .5rem .375rem;

        &:first-child {
            margin-left: 0;
        }
    }
}
</style>